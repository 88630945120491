import { useLocation, useNavigate } from '@remix-run/react';
import { useState } from 'react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';

type Options = {
  replace?: boolean;
  query?: {
    [key: string]: string;
  };
};

export function useOpenTrainingEditor(): (id: string, query?: Options) => void {
  const navigate = useNavigate();
  const location = useLocation();
  return useLiveCallback((id: string, options?: Options) => {
    const from = `${location.pathname}${location.search}`;
    const params = new URLSearchParams();
    if (options?.query) {
      for (const [key, value] of Object.entries(options.query)) {
        params.set(key, value);
      }
    }
    navigate(
      {
        pathname: `/trainings/${id}/edit`,
        search: params.size > 0 ? `?${params.toString()}` : undefined,
      },
      {
        state: { from },
        replace: options?.replace,
      }
    );
  });
}

function extraFrom(state: unknown): string | null {
  if (!state || typeof state !== 'object' || !('from' in state)) return null;
  const from = state['from'];
  if (typeof from === 'string') return from;
  return null;
}

export function useCloseTrainingEditor() {
  const navigate = useNavigate();
  const location = useLocation();
  const [from] = useState(extraFrom(location.state));

  return useLiveCallback(async () => {
    if (!from) {
      navigate(-1);
      return;
    }
    try {
      if (from.startsWith('/learning/assistant')) {
        navigate('/learning/admin');
        return;
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
      navigate(-1);
    }
  });
}
