import { Client } from '@langchain/langgraph-sdk';
import useSWRImmutable from 'swr/immutable';

import config from '../../config';

export class GraphUtils {
  static API_URL = `${config.app.baseUrl}/graph`;
  static CreateClient() {
    return new Client({
      apiUrl: GraphUtils.API_URL,
    });
  }
}

export function useLoadAssistant(graphId: string) {
  return useSWRImmutable(['/assistants/search', graphId], async () => {
    const client = GraphUtils.CreateClient();
    const assistants = await client.assistants.search({ graphId });
    return assistants[0];
  });
}
