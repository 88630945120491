import {
  type AIMessage,
  type HumanMessage,
  type Message,
  type ToolMessage,
} from '@langchain/langgraph-sdk';
import { useMemo, useState } from 'react';

import { BlockType } from '@lp-lib/game';
import {
  type BlockDraft,
  type Module,
} from '@lp-lib/shared-schema/src/agent/outline';

import { fromMediaDTO } from '../../utils/api-dto';
import { useGamePack } from '../Game/GamePack/useGamePack';
import { GamePackCoverImage } from '../GamePackCover/GamePackCoverPres';
import { ArrowDownIcon, ArrowUpIcon } from '../icons/Arrows';
import { SparkBlockIcon } from '../icons/Block';
import { ToolsIcon } from '../icons/ToolsIcon';
import { Loading } from '../Loading';
import { MarkdownBody } from '../MarkdownEditor';

type MessageContentImageUrl = Exclude<
  Exclude<Message['content'][number], string>,
  { type: 'text' }
>;

type TooCall = NonNullable<AIMessage['tool_calls']>[number];

function StringContent(props: {
  content: string;
  styles?: React.CSSProperties;
}) {
  return <MarkdownBody body={props.content} styles={props.styles} />;
}

function ImageContent(props: { content: MessageContentImageUrl['image_url'] }) {
  if (typeof props.content === 'string') {
    return <img src={props.content} alt='' width={100} />;
  }
  return <img src={props.content.url} alt='' width={100} />;
}

function MessageConentView(props: { content: Message['content'] }) {
  const { content } = props;
  if (typeof content === 'string') {
    return <StringContent content={content} />;
  } else {
    return (
      <div>
        {content.map((entry) => {
          return entry.type === 'text' ? (
            <StringContent content={entry.text} />
          ) : (
            <ImageContent content={entry.image_url} />
          );
        })}
      </div>
    );
  }
}

function ThreadUserMessage(props: { message: HumanMessage }) {
  return (
    <div className='self-end py-1 px-4 bg-light-gray rounded-xl'>
      <MessageConentView content={props.message.content} />
    </div>
  );
}

function TooCalledCard(props: { toolCall: TooCall }) {
  const { toolCall } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <div className='py-4 px-4 bg-lp-black-003 rounded-xl'>
      <div className='flex items-center justify-between'>
        <div>
          <span className='font-bold ml-2'>{toolCall.name}</span>
        </div>
        <button
          type='button'
          className='btn'
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </button>
      </div>
      <div
        className={`mt-2 text-xs text-secondary ${
          expanded ? 'block' : 'hidden'
        }`}
      >
        {JSON.stringify(toolCall.args)}
      </div>
    </div>
  );
}

function ThreadAssistantMessage(props: {
  message: AIMessage;
  showLoading: boolean;
}) {
  const { message } = props;
  return (
    <div className='flex flex-col gap-2'>
      {props.showLoading && (
        <div className='absolute transform -translate-x-full'>
          <Loading imgClassName='w-5 h-5' text='' />
        </div>
      )}
      <MessageConentView content={props.message.content} />
      {message.tool_calls?.map((toolCall, idx) => (
        <TooCalledCard key={`${toolCall.id}-${idx}`} toolCall={toolCall} />
      ))}
    </div>
  );
}

type SearchResult = {
  title: string;
  url: string;
  content: string;
  score: number;
};

function SearchResultView(props: { result: SearchResult }) {
  const { result } = props;
  const [expanded, setExpanded] = useState(false);
  return (
    <div key={result.url}>
      <div className='flex items-center justify-between'>
        <a
          className='text-primary underline'
          target='_blank'
          href={result.url}
          rel='noreferrer'
        >
          {result.title}
        </a>
        <button
          type='button'
          className='btn'
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <ArrowDownIcon /> : <ArrowUpIcon />}
        </button>
      </div>
      <div
        className={`text-xs text-secondary ${expanded ? 'block' : 'hidden'}`}
      >
        {result.content}
      </div>
    </div>
  );
}

function SearchToolMessage(props: { message: ToolMessage }) {
  const { message } = props;
  const results = useMemo(() => {
    if (typeof message.content !== 'string') {
      return [];
    }
    return JSON.parse(message.content) as SearchResult[];
  }, [message.content]);

  return (
    <div className='p-4 bg-dark-gray rounded-xl'>
      <div className='font-bold'>Search Results:</div>
      <div className='flex flex-col gap-2'>
        {results.map((result) => (
          <SearchResultView key={result.url} result={result} />
        ))}
      </div>
    </div>
  );
}

function mapBlockType(blockType: BlockDraft['blockType']) {
  switch (blockType) {
    case 'markdown':
      return BlockType.SLIDE;
    case 'slideBulletPoints':
    case 'slideBulletPointsMedia':
    case 'slideFullHost':
      return BlockType.SLIDE;
    case 'question':
      return BlockType.QUESTION;
    case 'multipleChoice':
      return BlockType.MULTIPLE_CHOICE;
    case 'clickToMatch':
      return BlockType.MEMORY_MATCH;
    case 'fillInTheBlanks':
      return BlockType.FILL_IN_THE_BLANKS;
    case 'jeopardy':
      return BlockType.JEOPARDY;
    case 'swipeToWin':
      return BlockType.SWIPE_TO_WIN;
    case 'scenario':
      return BlockType.SCENARIO;
    default:
      return blockType;
  }
}

function OutlineBlockDraft(props: { blockDraft: BlockDraft; index: number }) {
  const { blockDraft } = props;
  const blockType = useMemo(
    () => mapBlockType(blockDraft.blockType),
    [blockDraft.blockType]
  );
  const detail = useMemo(() => {
    return `${blockDraft.detail}\n\n[Notes: ${blockDraft.notes || 'None'}]`;
  }, [blockDraft.detail, blockDraft.notes]);
  if (!blockType) return null;
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center gap-2'>
        <div>{props.index + 1}.</div>
        {blockDraft.blockType ? (
          <SparkBlockIcon
            className='flex-shrink-0 w-6 h-6'
            blockType={blockType}
          />
        ) : (
          ''
        )}
        <div className='text-sms font-bold'>{blockDraft.gist}</div>
      </div>
      <StringContent
        content={detail ?? ''}
        styles={{
          fontSize: '13px',
        }}
      />
    </div>
  );
}

function OutlineModule(props: { module: Module }) {
  const { module } = props;
  return (
    <div className='flex flex-col gap-5'>
      <div className='flex items-center gap-2'>
        <div className='bg-tertiary w-6 h-6 rounded-md flex items-center justify-center'>
          <ToolsIcon className='w-4 h-4 fill-current' />
        </div>
        <div className='text-sms'>{module.name}</div>
      </div>
      <div className='flex flex-col gap-5'>
        {module.blockDrafts.map((blockDraft, idx) => (
          <OutlineBlockDraft
            key={blockDraft.id}
            index={idx}
            blockDraft={blockDraft}
          />
        ))}
      </div>
    </div>
  );
}

function OutlineToolMessage(props: { message: ToolMessage }) {
  const { message } = props;
  const modules = useMemo(() => {
    if (typeof message.content !== 'string') {
      return [];
    }
    return JSON.parse(message.content) as Module[];
  }, [message.content]);
  return (
    <div className='p-4 bg-dark-gray rounded-xl flex flex-col gap-4 text-white'>
      {modules.map((module, idx) => (
        <OutlineModule key={idx} module={module} />
      ))}
    </div>
  );
}

function OutlineCourseToolMessage(props: { message: ToolMessage }) {
  const { message } = props;
  const info = useMemo(() => {
    if (typeof message.content !== 'string') {
      return null;
    }
    return JSON.parse(message.content) as {
      id: string;
      name: string;
      description: string;
    };
  }, [message.content]);
  const { data: course } = useGamePack(info?.id);
  const cover = fromMediaDTO(course?.cover);
  return (
    <div className='p-4 bg-dark-gray rounded-xl'>
      <div
        className={`w-47.5 bg-transparent bg-opacity-15 rounded-xl 
        flex flex-col p-2.5 gap-2.5 flex-shrink-0`}
      >
        <GamePackCoverImage
          pack={{ cover }}
          alt='cover'
          className='w-full flex-none rounded-xl'
        />
        <div className='text-sms flex-none font-bold text-center'>
          {info?.name}
        </div>
      </div>
    </div>
  );
}

function ThreadToolMessage(props: { message: ToolMessage }) {
  const { message } = props;
  if (message.name === 'tavily_search_results_json') {
    return <SearchToolMessage message={message} />;
  } else if (message.name === 'generateOutline') {
    return <OutlineToolMessage message={message} />;
  } else if (message.name === 'generateCourseName') {
    return <OutlineCourseToolMessage message={message} />;
  }
}

export function ThreadMessage(props: {
  message: Message;
  showLoading: boolean;
}) {
  const { message } = props;
  if (message.type === 'human') {
    return <ThreadUserMessage message={message} />;
  } else if (message.type === 'ai') {
    return (
      <ThreadAssistantMessage
        message={message}
        showLoading={props.showLoading}
      />
    );
  } else if (message.type === 'tool') {
    return <ThreadToolMessage message={message} />;
  }
  return <></>;
}
